import { require } from './../utils/http'
//登录
function login(data) {
  console.log(data)
  return require('post', '/api/kapin_ee/user/v1/login_pc/', data)
}
//注册
function register(data) {
  console.log(data)
  return require('post', '/api/kapin_ee/user/v1/new_register/', data)
}
//验证码
function sendCodeApi(data) {
  console.log(data)
  return require('post', '/api/kapin_ee/user/v1/send_code/', data)
}
//校验验证码
function check_code(data) {
  console.log(data)
  return require('post', '/api/kapin_ee/user/v1/check_code/', data)
}

//更新简历时间
function source_update() {

  return require('post', '/api/kapin_ee/user/v1/source_update/', {})
}

//获取首页热门职位
function pc_position_hot(data) {
  console.log(data)
  return require('post', '/api/kapin_ee_admin/auth/v1/pc_position_hot/', data)
}

//职位详情
function pc_position_details(data) {
  console.log(data)
  return require('post', '/api/kapin_ee_admin/auth/v1/pc_position_details/', data)
}

//获取首页热门公司
function pc_company_list(data) {
  return require('post', '/api/kapin_ee_admin/auth/v1/pc_company_list/', data)
}

//获取首页热门公司
function pc_company_details(data) {
  return require('post', '/api/kapin_ee_admin/auth/v1/pc_company_details/', data)
}

//获取登录小程序码
function pc_creatqrcode(data) {
  return require('post', '/api/kapin_ee_admin/auth/v1/pc_creatqrcode/', data)
}
//获取登录小程序码
function pc_get_rangecode(data) {
  return require('post', '/api/kapin_ee_admin/auth/v1/pc_get_rangecode/', data)
}

//创建简历
function create_resume(data) {
  return require('post', '/api/kapin_elite/v1/create_resume/', data)
}

//获取简历信息
function resume_info(data) {
  return require('post', '/api/kapin_elite/v1/resume_info/', data)
}

function work_experience_update(data) {
  return require('post', '/api/resume/v1/work_experience_update/', data)
}

function education_experience_update(data) {
  return require('post', '/api/resume/v1/education_experience_update/', data)
}

function basic_update(data) {
  return require('post', '/api/resume/v1/basic_update/', data)
}
function expect_position_update(data) {
  return require('post', '/api/resume/v1/expect_position_update/', data)
}
function resume_hide(data) {
  return require('post', '/api/kapin_elite/v1/resume_hide/', data)
}

//精英获取聊天列表
function chat_list(data) {
  return require('post', '/api/kapin_elite/v1/chat_list/', data)
}
//精英获取聊天详情
function chat_detail(data) {
  return require('post', '/api/kapin_elite/v1/chat_detail/', data)
}

//精英 发送聊天信息
function chat_add(data) {
  return require('post', '/api/kapin_elite/v1/chat_add/', data)
}

//精英 发送短信验证码
function send_code(data) {
  return require('post', '/api/kapin_ee/user/v1/send_code/', data)
}

//精英 手机号登录
function elite_login_pc(data) {
  return require('post', '/api/kapin_elite/v1/elite_login_pc/', data)
}
//精英 投递简历
function send_resume(data) {
  return require('post', '/api/kapin_elite/v1/send_resume/', data)
}


//导入要监视的手机号
function input_tsmobile(data) {
  return require('post', '/api/kapin_ee_admin/auth/v1/input_tsmobile/', data)
}

// 支付回调页面
function pay_notify_xcx(data) {
  return require('post', '/api/kapin_ee/liechang/v1/pay_notify_xcx', data)
}
 

export {
  login, register, sendCodeApi, check_code, source_update, pc_position_hot, pc_company_list,
  pc_position_details, pc_company_details, pc_creatqrcode, pc_get_rangecode, create_resume, resume_info,
  work_experience_update, education_experience_update, basic_update, expect_position_update,
  resume_hide, chat_list, chat_detail, chat_add, send_code, elite_login_pc, send_resume, input_tsmobile,pay_notify_xcx
}
